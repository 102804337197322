import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaChevronDown } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Select from 'react-select';

const cities = {
    "AbaAlworood": "ابا الورود",
    "Abha": "ابها",
    "AbhaManhal": "ابها المنهل",
    "Abqaiq": "بقيق",
    "AbuAjram": "ابو عجرم",
    "AbuAreish": "ابو عريش",
    "AdDahinah": "الداهنة",
    "AdDubaiyah": "الضبيعة",
    "Addayer": "الداير",
    "Adham": "أضم",
    "Afif": "عفيف",
    "Aflaj": "افلج",
    "AhadMasarha": "احد مسارحه",
    "AhadRufaidah" : "احد رفيده",
    "AinDar" : "عين دار",
    "AlAdari" : "الادري",
    "AlAis" : "العيص",
    "AlAjfar" : "الاجفار",
    "AlAmmarah" : "العامرية",
    "AlArdah" : "العارضة",
    "AlArja" : "العرجة",
    "AlAsyah" : "العاصية",
    "AlBada" : "البدع",
    "AlBashayer" : "البشاير",
    "AlBatra" : "البترا",
    "AlBijadyah" : "البجادية ",
    "AlDalemya" : "الدليميه",
    "AlFuwaileq" : "الفويلق",
    "AlHaith" : "الحائط ",
    "AlHassa" : "الاحساء",
    "AlHayathem" : "الهياثم",
    "AlHufayyirah" : "الحفيرة",
    "AlHulayfahAsSufla": " الحليفة السفلى ", 
    "AlIdabi": "العيدابي",
    "AlKhishaybi": "الخشيبي",
    "AlKhitah": "الخطة ",
    "AlLaqayit": "اللقايط",
    "AlMada": "المدا",
    "AlMadaya": "الماضايا",
    "AlMahani": "المحاني",
    "AlMahd": "المهد",
    "AlMidrij": "المدراج",
    "AlMoya": "الموية",
    "AlQarin": "قنا",
    "AlUwayqilah": "العويقيلية",
    "AlWasayta": "الوسيطاء",
    "Alghat": "الغاط",
    "Alhada": "الهدى",
    "Al-Jsh": "الجش",
    "Alnabhanya": "النبهانية",
    "AlRass": "الرس",
    "Amaq": "عمق",
    "AnNabkAbuQasr": "النبك ابو قصر",
    "AnNafiah": "النافية",
    "AnNuqrah": "النقرة",
    "Anak": "عنك",
    "Aqiq": "عقيق",
    "Aqool": "عقول",
    "ArRadifah": "الرديفه",
    "ArRafi'ah": "الرفيعة",
    "Arar": "عرعر",
    "Artawiah": "الارطاوية",
    "AsSulaimaniyah": "عشيرة",
    "AsSulubiayh": "الصلبيّة",
    "Asfan": "عسفان",
    "AshShaara": "الشرائع",
    "AshShamli": "الشملي",
    "AshShananah": "الشنانه",
    "AshShimasiyah": "الشماسية",
    "AshShuqaiq": "الشقيق",
    "Asheirah": "عشيرة",
    "AtTuwayr": "الطوير",
    "Atawleh": "اطاولة",
    "AthThybiyah": "الذيبيه - حفر الباطن",
    "Awamiah": "عواميه",
    "AynFuhayd": "عين فهيد",
    "Badaya": "البدائع",
    "Bader": "بدر",
    "BadrAlJanoub": "بدر الجنوب",
    "Baha": "الباحة",
    "Bahara": "بحرا",
    "BahrAbuSukaynah" : "بحر ابو سكينة",
    "BahratAlMoujoud": "بحرة الموجود",
    "Balahmar": "بلحمر",
    "Balasmar": "بللسمر",
    "Balqarn": "بلقرن",
    "BaqaAshSharqiyah": "باقة الشرقيه",
    "Baqaa": "بقعة",
    "Baqiq": "بقيق",
    "Bareq": "بارق",
    "Batha": "بطحه",
    "BilJurashi": "بلجرشي",
    "Birk": "برك",
    "Bish": "بيش",
    "Bisha": "بيشة",
    "Bukeiriah": "البكيرية",
    "Buraidah": "بريدة",
    "Daelim": "الدلم",
    "Damad": "ضمد",
    "Dammam": "الدمام",
    "Darb": "درب",
    "Dariyah": "ضرية",
    "Dawadmi": "الدوادمي",
    "Deraab": "ديراب",
    "Dere'iyeh": "الدرعية",
    "Dhabyah": "ظبيه",
    "Dhahban": "دهبان",
    "Dhahran": "الظهران",
    "DhahranAlJanoob": "ظهران الجنوب",
    "Dhurma": "الدرمه",
    "DomatAlJandal": "دومة الجندل",
    "Duba": "ضبا",
    "Duhknah": "دخنة ",
    "DulayRashid": "ضليع رشيد",
    "Farasan": "فرسان",
    "Ghazalah": "غزالية",
    "Ghtai": "غطي ",
    "Gilwa": "قلوه",
    "Gizan": "جازان",
    "Hadeethah": "الحديثه",
    "HaferAlBatin": "حفر الباطن",
    "Hail": "حائل",
    "Hajrah": "حجره",
    "HalatAmmar": "حالة عمار",
    "Hali ": "حلي",
    "Haqil": "حقل",
    "Harad": "حرض",
    "Harajah": "حرجه",
    "Hareeq": "الحريق",
    "Hawea/Taif": "حوية/طائف",
    "Haweyah/Dha": "هويه/الدمام",
    "HawtatBaniTamim": "حوطة بني تميم",
    "HazmAlJalamid": "حزم الجلاميد",
    "Hedeb": "هديب",
    "Hinakeya": "الحناكية",
    "Hofuf": "الهفوف",
    "Horaimal": "حريملاء",
    "HotatSudair": "حوطة سدير",
    "Hubuna": "حبونا",
    "Huraymala": "حريملاء",
    "Huroob ": "هروب",
    "Ja'araneh": "الجعرانه",
    "Jafar": "جفر",
    "Jalajel": "جلاجل",
    "Jeddah": "جدة",
    "Jouf": "الجوف",
    "Jubail": "الجبيل",
    "Jumum": "الجموم",
    "Kahlah": "كحله",
    "Kara": "كارا",
    "Kara'a": "كرع",
    "Karboos": "كربوس",
    "Khafji": "الخفجي",
    "Khaibar": "خيبر",
    "Khairan": "تمرة ",
    "Khamaseen": "خماسين",
    "KhamisMushait": "خميس مشيط",
    "Kharj": "الخرج",
    "Khasawyah": "خصاويه",
    "Khobar": "الخبر",
    "Khodaria": "خضريه",
    "Khulais": "خليص",
    "Khurma": "الخرمة",
    "KingAbdullahEconomicCity'KAEC'": "مدينة الملك عبدالله الاقتصادية",
    "KingKhalidMilitaryCity": "مدينة الملك خالد العسكرية ",
    "Kubadah": "كبضه",
    "Laith": "ليث",
    "Layla": "ليلى",
    "Madinah": "المدينة المنورة",
    "MahadAlDahab": "مهد الدهب",
    "Majarda": "المجاردة",
    "Majma": "المجمعة",
    "Makkah": "مكة المكرمة",
    "Mandak": "مندق",
    "Mastura": "مستوره",
    "Mawqaq": "موقق",
    "Midinhab": "المذنب",
    "Mikhwa": "مخواه",
    "MohayelAseer": "محايل",
    "Moqaq": "مقيق",
    "Mrat": "مرات",
    "Mubaraz": "المبرز",
    "Mubayid": "مبايض",
    "Mulaija": "الموليجه",
    "Mulayh": "مليح ",
    "MunifatAlQaid": "منيفت القيد",
    "Muthaleif": "المظيلف",
    "Muzahmiah": "المزاحمية",
    "Muzneb": "مذنب",
    "Nabiya": "نبيه",
    "Najran": "نجران",
    "Namas": "نماص",
    "NewMuwayh": "مويه الجديدة",
    "Nimra": "نمره",
    "Nisab": "نصاب",
    "Noweirieh": "النعيرية",
    "Ojam": "عجم",
    "Onaiza": "عنيزة",
    "Othmanyah": "العثمانيه",
    "Oula": "العلا",
    "Oyaynah": "العيينة",
    "OyoonAlJawa": "عيون الجوا",
    "Qahmah": "القحمة",
    "Qana ": "قنا",
    "Qarah": "قاره",
    "QariyaAlOlaya": "قرية العليا",
    "Qasab": "قصب",
    "Qassim": "القصيم",
    "Qatif": "القطيف",
    "Qaysoomah": "قيصومه",
    "Qbah": "قبه",
    "Qouz": "القوز",
    "Qufar": "قفار",
    "Qunfudah": "القنفذة",
    "Qurayat": "القريات",
    "Qusayba": "قصيباء",
    "Quwei'ieh": "القويعية",
    "Rabigh": "رابغ",
    "Rafha": "رفحاء",
    "Rahima": "رحمه",
    "Rania": "رانيا",
    "RasAlKheir": "راس الخير",
    "RasBaridi": "طارق أملج",
    "RasTanura": "راس تنورة",
    "RawdatHabbas": "روضه هباس",
    "RejalAlma'a": "رجال المع",
    "Remah": "رماح",
    "Riyadh": "الرياض",
    "RiyadhAlKhabra": "رياض الخبراء",
    "RowdatSodair": "روضه سودير",
    "RvayaAljamsh": "رافا الجماش",
    "Rwaydah": "الرويضه",
    "SabtElAlaya": "سبت العلايا",
    "Sabya": "صبيا",
    "SadalMalik": "سعد أل مالك",
    "Sadyan": "سديان",
    "Safanyah": "سفنيه",
    "Safwa": "صفوى",
    "Sahna": "سحنه",
    "Sajir": "ساجر",
    "Sakaka": "سكاكا",
    "Salbookh": "سلبوكه",
    "Salwa": "سلوى",
    "Samakh": "سمكه",
    "Samtah": "صامطة",
    "Saqf": "سقف",
    "Sarar": "صراره",
    "SaratObeida": "سراة عبيده",
    "Satorp(JubailInd'l2)": "الجبيل الصناعية 2",
    "Seihat": "سيهات",
    "Shaqra": "شقراء",
    "Shari": "شري ",
    "Sharourah": "شرورة",
    "Shefa'a": "شفا",
    "Shinanh": "شينانة",
    "Shoaiba": "الشعيبه",
    "Shraie'e": "الشرايع",
    "Shumeisi": "الشميسي",
    "Siir": "سر",
    "Simira": "سيميرا",
    "Subheka": "صبيخة",
    "Sulaiyl": "السليل",
    "Suwayr": "صوير",
    "Tablah": "طبلة",
    "Tabrjal": "تبرجل",
    "Tabuk": "تبوك",
    "Taiba": "طيبه",
    "Taif": "الطائف",
    "Tanda": "تندحة",
    "Tanjeeb": "تنجيب",
    "Tanuma": "تنوما",
    "Tanumah": "تنيوما",
    "Tarut": "تاروت",
    "Tatleeth": "تثليث",
    "Tayma": "تيماء",
    "Tebrak": "تبراك",
    "Thabya": "ذبية",
    "Thadek": "ثادق",
    "Tharmada": "ثرمادا",
    "Thebea": "الذيبية - القصيم",
    "Thumair": "تمير",
    "Thuqba": "ثقبا",
    "Towal": "ثول",
    "Turaib": "طريب",
    "Turaif": "طريف",
    "Turba": "تربه",
    "Tuwaim": "تويم",
    "Udhaliyah": "الدهليه",
    "UmAljamajim": "ام الجماجم",
    "Umluj": "املج",
    "UqlatAlSuqur": "عقلة الصقور",
    "Ushayqir": "اشيقر",
    "Uyun": "العيون",
    "Wadeien": "الواديين",
    "WadiBinHasbal": "وادي بن هشبل",
    "WadiElDwaser": "وادي الدواسر ",
    "WadiFaraah": "وادي فرح",
    "WadiFatmah": "وادي فاطمه",
    "Wajeh(AlWajh)": "الوجه",
    "WalyALAhd": "ولي العهد",
    "Yadamah": "يدمة",
    "Yanbu": "ينبع",
    "YanbuAlBaher": "ينبع البحر",
    "YanbuNakhil": "ينبع النخيل",
    "Yuthma": "يوتما",
    "Zallum": "زلوم",
    "Zulfi": "الزلفي",
};




const bankNameMapping = {
    80: "مصرف الراجحي",
    20: "بنك الرياض",
    "05": "مصرف الإنماء",
    10: "البنك الأهلي السعودي",
    55: "البنك السعودي الفرنسي",
    45: "البنك السعودي البريطاني",
    15: "بنك البلاد",
    65: "البنك السعودي للاستثمار",
    30: "البنك العربي",
    60: "بنك الجزيرة",
};
const bank_codes = {
    '80': 'RJHI',
    '20': 'RIBL',
    '05': 'INMA',
    '10': 'NCBK',
    '55': 'BSFR',
    '45': 'SABB',
    '15': 'ALBI',
    '65': 'SIBC',
    '30': 'ARNB',
    '60': 'BJAZ'
}


const customStyles = {
    container: (provided) => ({
        ...provided,
        direction: 'rtl', // Ensure text direction is RTL
    }),
    control: (provided) => ({
        ...provided,
        textAlign: 'right', // Align text to the right
        padding: '0', // Remove default padding
        border: '1px solid #ccc', // Border style
        borderRadius: '0.375rem', // Border radius for rounded corners
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)', // Optional: shadow for better visibility
    }),
    placeholder: (provided) => ({
        ...provided,
        textAlign: 'right', // Align placeholder text to the right
        direction: 'rtl', // Right-to-left text direction
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: 'right', // Align selected value text to the right
        direction: 'rtl', // Right-to-left text direction
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0', // Remove default padding
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none', // Hide the indicator separator if needed
    }),
};

const apiUrl = process.env.REACT_APP_API_URL;



export const RefundForm = () => {
    const location = useLocation();
    const {
        totalAmount,
        totalAmountWithTax,
        notRefundable,
        refundFee,
        totalRefundedAmount
    } = location.state || {};
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showOtherFields, setShowOtherFields] = useState(false);
    const { t } = useTranslation();
    const { totalPrice, orderData, orderItems, setTotalPrice, setTotalQuantity, setOrderData, setOrderItems } = useAuth();
    const navigate = useNavigate();
    const paymentMethodIncludesTabbyOrTamara = orderData?.payment_method?.toLowerCase().includes('tabby') || orderData?.payment_method?.toLowerCase().includes('tamara');
    const getBankName = (iban) => {
        if (!iban.startsWith("SA") || iban.length !== 24) {
            return t("unknown-bank");
        }
        const bankIdentifier = iban.substring(4, 6);
        return t(bankNameMapping[bankIdentifier] || "unknown-bank");
    };

    const ibanValidationSchema = Yup.string()
        .transform(value => value?.replace(/[-\s]/g, '')) // Removes dashes & spaces
        .matches(/^SA[0-9A-Z]{22}$/, t('invalid-iban-format')) // Ensures correct format
        .required(t('iban-required'))
        .test("remove-spaces", t('iban-no-spaces'), (value) => !/\s/.test(value))
        .transform(value => value.replace(/\s+/g, '')); // Removes spaces

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t("first-name-required")).test('not-a-url', t('cannotBeUrl'), value => {
            const trimmedValue = value?.trim();
            const urlRegex = /^(https?:\/\/(?:www\.)?[^\s$.?#].[^\s]*$)/;
            const simpleUrlRegex = /^(?!\s*$)(\S+\.\S+|\S+\.\S+\.\S+)(?!\S)/;
            const invalidContentRegex = /(\S+\.\S+.*[^\s])$/;
            return !urlRegex.test(trimmedValue) && !simpleUrlRegex.test(trimmedValue) && !invalidContentRegex.test(trimmedValue);
        }),
        secondName: Yup.string().required(t("second-name-required")).test('not-a-url', t('cannotBeUrl'), value => {
            const trimmedValue = value?.trim();
            const urlRegex = /^(https?:\/\/(?:www\.)?[^\s$.?#].[^\s]*$)/;
            const simpleUrlRegex = /^(?!\s*$)(\S+\.\S+|\S+\.\S+\.\S+)(?!\S)/;
            const invalidContentRegex = /(\S+\.\S+.*[^\s])$/;
            return !urlRegex.test(trimmedValue) && !simpleUrlRegex.test(trimmedValue) && !invalidContentRegex.test(trimmedValue);
        }),
        iban: !paymentMethodIncludesTabbyOrTamara ? ibanValidationSchema : Yup.string(),
        email: Yup.string().email(t("invalid-email-format")).required(t("email-required")),
        reason: Yup.string().required(t("reason-required")).test('not-a-url', t('cannotBeUrl'), value => {
            const trimmedValue = value?.trim();
            const urlRegex = /^(https?:\/\/(?:www\.)?[^\s$.?#].[^\s]*$)/;
            const simpleUrlRegex = /^(?!\s*$)(\S+\.\S+|\S+\.\S+\.\S+)(?!\S)/;
            const invalidContentRegex = /(\S+\.\S+.*[^\s])$/;
            return !urlRegex.test(trimmedValue) && !simpleUrlRegex.test(trimmedValue) && !invalidContentRegex.test(trimmedValue);
        }),
        city: Yup.string().required(t("city-required")),
        address: Yup.string().required(t("address-required")).test('not-a-url', t('cannotBeUrl'), value => {
            const trimmedValue = value?.trim();
            const urlRegex = /^(https?:\/\/(?:www\.)?[^\s$.?#].[^\s]*$)/;
            const simpleUrlRegex = /^(?!\s*$)(\S+\.\S+|\S+\.\S+\.\S+)(?!\S)/;
            const invalidContentRegex = /(\S+\.\S+.*[^\s])$/;
            return !urlRegex.test(trimmedValue) && !simpleUrlRegex.test(trimmedValue) && !invalidContentRegex.test(trimmedValue);
        }),
        others: showOtherFields ? Yup.string().required(t("reason-required")).test('not-a-url', t('cannotBeUrl'), value => {
            const trimmedValue = value?.trim();
            const urlRegex = /^(https?:\/\/(?:www\.)?[^\s$.?#].[^\s]*$)/;
            const simpleUrlRegex = /^(?!\s*$)(\S+\.\S+|\S+\.\S+\.\S+)(?!\S)/;
            const invalidContentRegex = /(\S+\.\S+.*[^\s])$/;
            return !urlRegex.test(trimmedValue) && !simpleUrlRegex.test(trimmedValue) && !invalidContentRegex.test(trimmedValue);
        })
            : Yup.string()
    });

    useEffect(() => {
        const cachedOrderData = JSON.parse(localStorage.getItem('orderData'));
        const cachedOrderItems = JSON.parse(localStorage.getItem('orderItems'));
        const cachedTotalPrice = localStorage.getItem('totalPrice');
        const cachedTotalQuantity = localStorage.getItem('totalQuantity');

        if (cachedOrderData && cachedOrderItems) {
            setOrderData(cachedOrderData);
            setOrderItems(cachedOrderItems);
            setTotalPrice(cachedTotalPrice);
            setTotalQuantity(cachedTotalQuantity);

            formik.setValues({
                firstName: cachedOrderData?.Customer?.first_name || "",
                secondName: cachedOrderData?.Customer?.last_name || "",
                iban: "",
                email: cachedOrderData?.Customer?.email || "",
                reason: "",
                city: "",
                address: ""
            });

            setCitiesOptions(Object.keys(cities).map(key => ({
                value: key,
                label: cities[key]
            })));
        }
    }, []);


    const handleReasonChange = (event) => {
        const selectedReason = event.target.value;
        formik.setFieldValue('reason', selectedReason);
        setShowOtherFields(selectedReason === 'other'); // Show textarea if 'other' is selected
    };

    const handleLoading = (value) => setLoading(value)

    const getCityKey = (arabicCityName) => {
        return Object.keys(cities).find(key => cities[key] === arabicCityName);
    };

    const getBankKey = (bankValue) => {
        const key = Object.keys(bankNameMapping).find(key => bankNameMapping[key] === bankValue)
        return bank_codes[`${key}`]
    }
    const formik = useFormik({
        initialValues: {
            firstName: orderData?.Customer?.first_name || "",
            secondName: orderData?.Customer?.last_name || "",
            iban: "",
            email: orderData?.Customer?.email || "",
            reason: "",
            city: citiesOptions.find(option => option.value === orderData?.Customer?.city) || "",
            address: orderData?.Customer?.address || "",
            others: "", // Field for the "other" reason
        },
        validationSchema,
        onSubmit: async (values) => {
            const bankName = getBankName(values.iban);

            if (!paymentMethodIncludesTabbyOrTamara && bankName === t("unknown-bank")) {
                toast.error(t("invalid-bank"));
                return;
            }
            const returnItems = orderItems
                .filter(item => item.refund_quantity > 0)
                .map(item => ({
                    product_id: item.Product.id,
                    quantity: item.refund_quantity
                }));
            const payload = {
                payment_method: orderData?.payment_method,
                items: returnItems,
                refund_amount: (Math.floor((totalPrice - 0.01) * 100) / 100) - 0 > 0 ? parseFloat(((Math.floor((totalPrice - 0.01) * 100) / 100) - 0).toFixed(2)) : 0,
                city: values.city,
                reason: values.reason === "other" ? values.others : values.reason,
                first_name: values.firstName,
                last_name: values.secondName,
                email: values.email,
                bank_code: '',
                address: values.address,
                total_with_tax: totalAmountWithTax,
                total_refundable_amount: totalAmount,
                total_non_refundable_amount: notRefundable,
                return_fees: refundFee
            }

            if (values.iban) {
                payload.iban = values.iban
                payload.bank_code = getBankKey(getBankName(formik.values.iban))
            }
            const token = localStorage.getItem('token');
            try {
                handleLoading(true)
                const response = await axios.post(
                    `${apiUrl}/refund-requests`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                handleLoading(false)
                if (response?.data?.uuid) {
                    toast.success("successfully submitted the refund request")
                    setTotalPrice(0);
                    setTotalQuantity(0);
                    localStorage.removeItem('orderData');
                    localStorage.removeItem('orderItems');
                    localStorage.removeItem('totalPrice');
                    localStorage.removeItem('totalQuantity');
                    setTimeout(() => {
                        navigate(`/bank-info/confirmation/${response?.data?.uuid}`);
                    }, 1000)
                }
            } catch ({ response }) {
                handleLoading(false)
                toast.error(response?.data?.message)
            }
        },
    });

    return (
        <div className="flex justify-center items-start mt-12 p-4">
            <ToastContainer />
            <div className="w-full md:w-1/2 lg:w-1/3 bg-white p-6 rounded-lg shadow-lg">
                <form onSubmit={formik.handleSubmit} className="space-y-5">
                    <div className="relative">
                        <label className="text-sm text-gray-600 mr-2 flex justify-end">
                            {t("first-name")}
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input w-full text-right placeholder-right border border-gray-300 p-2"
                            placeholder={t("first-name")}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="text-red-500 text-sm mt-1 text-right">
                                {formik.errors.firstName}
                            </div>
                        ) : null}
                    </div>

                    <div className="relative">
                        <label className="text-sm text-gray-600 mr-2 flex justify-end">
                            {t("second-name")}
                        </label>
                        <input
                            type="text"
                            name="secondName"
                            value={formik.values.secondName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input w-full text-right placeholder-right border border-gray-300 p-2"
                            placeholder={t("second-name")}
                        />
                        {formik.touched.secondName && formik.errors.secondName ? (
                            <div className="text-red-500 text-sm mt-1 text-right">
                                {formik.errors.secondName}
                            </div>
                        ) : null}
                    </div>

                    {!paymentMethodIncludesTabbyOrTamara && (
                        <div className="relative">
                            <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                {t("iban")}
                            </label>
                            <input
                                type="text"
                                name="iban"
                                value={formik.values.iban}
                                onChange={(e) => {
                                    // Remove both hyphens and spaces
                                    const cleanedValue = e.target.value.replace(/[-\s]/g, '');
                                    formik.setFieldValue('iban', cleanedValue);
                                }}
                                onBlur={formik.handleBlur}
                                className="form-input w-full text-right placeholder-right border border-gray-300 p-2"
                                placeholder={t("iban")}
                            />
                            {formik.touched.iban && formik.errors.iban ? (
                                <div className="text-red-500 text-sm mt-1 text-right">
                                    {formik.errors.iban}
                                </div>
                            ) : null}
                            {formik.values.iban && !formik.errors.iban && (
                                <div className="text-gray-700 text-sm mt-2">
                                    {t("bank-name")}: {getBankName(formik.values.iban)}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="relative">
                        <label className="text-sm text-gray-600 mr-2 flex justify-end">
                            {t("email")}
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-input w-full text-right placeholder-right border border-gray-300 p-2"
                            placeholder={t("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-500 text-sm mt-1 text-right">
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </div>

                    <div className="flex flex-col items-end space-y-4">
                        <div className="relative w-full">
                            <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                {t("select-reason")}
                            </label>
                            <select
                                name="reason"
                                value={formik.values.reason}
                                onChange={handleReasonChange}
                                onBlur={formik.handleBlur}
                                className="form-select w-full text-right appearance-none pr-2 py-2 border border-gray-400"
                            >
                                <option value="" disabled>
                                    {t("select-reason")}
                                </option>
                                <option value="perfume didn't suite me">
                                    {t("perfume-didn't-suite-me")}
                                </option>
                                <option value="I’d like to try another perfume">
                                    {t("I’d like to try another perfume")}
                                </option>
                                <option value="other">
                                    {t("other")}
                                </option>
                            </select>
                            <div className="absolute top-[65%] left-0 transform -translate-y-[40%] flex items-center px-2 pointer-events-none">
                                <FaChevronDown className="text-gray-400" />
                            </div>
                            {formik.touched.reason && formik.errors.reason ? (
                                <div className="text-red-500 text-sm mt-1 text-right">
                                    {formik.errors.reason}
                                </div>
                            ) : null}
                        </div>


                        {showOtherFields && (
                            <div className="relative w-full">
                                <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                    {t("other")}
                                </label>
                                <input
                                    type="text"
                                    name="others"
                                    value={formik.values.others}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="form-input w-full text-right placeholder-right border border-gray-400 p-2"
                                    placeholder={t("other")}
                                />
                                {formik.touched.others && formik.errors.others ? (
                                    <div className="text-red-500 text-sm mt-1 text-right">
                                        {formik.errors.others}
                                    </div>
                                ) : null}
                            </div>
                        )}

                        <div className="relative w-full">
                            <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                {t("select-city")}
                            </label>
                            <Select
                                name="city"
                                options={citiesOptions}
                                value={citiesOptions.find(option => option.value === formik.values.city)}
                                onChange={(selectedOption) => {
                                    formik.setFieldValue('city', selectedOption.value);
                                }}
                                onBlur={formik.handleBlur}
                                placeholder={t("select-city")}
                                isClearable={false}
                                styles={customStyles}
                            />
                            {formik.touched.city && formik.errors.city ? (
                                <div className="text-red-500 text-sm mt-1 text-right">
                                    {formik.errors.city}
                                </div>
                            ) : null}
                        </div>

                        <div className="relative w-full">
                            <label className="text-sm text-gray-600 mr-2 flex justify-end">
                                {t("address")}
                            </label>
                            <textarea
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-textarea w-full text-right placeholder-right border border-gray-400 p-2 h-20 resize-none"
                                placeholder={t("address")}
                            />
                            {formik.touched.address && formik.errors.address ? (
                                <div className="text-red-500 text-sm mt-1 text-right">
                                    {formik.errors.address}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="text-left mt-6">
                        <button
                            disabled={loading}
                            type="submit"
                            className={`bg-black text-white py-2 px-4 rounded ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                        >
                            {t("complete-refund-request")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

